import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { PickListInfo } from '../../models/Picklist';
import DateUtils from '../../utils/DateUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import DataRow from '../shared/data-grid/DataRow';
import Button, { ButtonType } from '../shared/form-control/Button';
import { SearchInput } from '../shared/form-control/SearchInput';
import InfoIcon from '../shared/icon/InfoIcon';
import Loader from '../shared/Loader';
import { Heading, HeadingSize } from '../shared/text/Heading';
import { useRecoilValue } from 'recoil';
import { currentTenantIdAtom } from '../../recoil/atoms/Clients';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import StandardModal from '../shared/modal/variants/StandardModal';
import TranslatableInput from '../shared/form-control/TranslatableInput';
import { Translations } from '../../models/Translation';

type PicklistEditorProps = {
  clientId?: string;
  picklists: PickListInfo[];
  searchPhrase: string;
  showLoadMore: boolean;
  onAdd: (translations: Translations<'name' | 'description'>) => void;
  onArchive: (code: string) => void;
  onUnarchive: (code: string) => void;
  onEdit: (picklist: PickListInfo) => void;
  onSearchPhraseChange: (value: string) => void;
  onLoadMore: () => void;
  changeType: (isSystem: boolean, picklist: PickListInfo) => void;
  isLoading: boolean;
};

const PicklistEditor: FC<PicklistEditorProps> = (props) => {
  const {
    clientId,
    searchPhrase,
    picklists,
    showLoadMore,
    onAdd,
    onArchive,
    onEdit,
    onSearchPhraseChange,
    onLoadMore,
    changeType,
    onUnarchive,
    isLoading,
  } = props;
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showNewPicklistModal, setShowNewPicklistModal] = useState(false);
  const [newPickListTranslations, setNewPickListTranslations] = useState<Translations<'name' | 'description'>>({});
  const [selectedPicklist, setSelectedPicklist] = useState<PickListInfo | null>(null);
  const [editingPicklists, setEditingPicklists] = useState<Record<string, PickListInfo>>({});

  const { t } = useTranslation(['navigation', 'organisation', 'common']);

  const updatePicklistTranslations = useCallback((id: string, value: Translations) => {
    setEditingPicklists((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        translations: value,
      },
    }));
  }, []);

  const stopEditing = useCallback((id: string) => {
    setEditingPicklists((prev) => {
      const { [id]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const saveEdit = useCallback(
    (id: string) => {
      onEdit(editingPicklists[id]);
      stopEditing(id);
    },
    [editingPicklists, onEdit, stopEditing],
  );

  const [lastElementRef] = useInfiniteScroll(showLoadMore ? onLoadMore : null, isLoading);

  return (
    <div className="flex h-full flex-col pt-6">
      <div className="flex justify-between">
        <Heading size={HeadingSize.H3}>{t('organisation:tabs.client-picklists')}</Heading>
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center justify-between gap-4">
            <div className="w-80">
              <SearchInput
                autoFocus
                id="picklist-search"
                placeholder={t('common:list.filter.search')}
                value={searchPhrase}
                onChange={(e) => onSearchPhraseChange(e.target.value)}
              />
            </div>

            <Button type={ButtonType.PRIMARY} onClick={() => setShowNewPicklistModal(true)}>
              {t('organisation:picklists.buttons.new-list')}
            </Button>
          </div>
        </div>
      </div>
      <div className="px-2 py-8">
        <div className="flex items-center pl-4">
          <div className="w-1/2">{t('organisation:picklists.list.heading.name')}</div>
          <div className="flex w-1/2 items-center">
            <div className="w-1/3 text-center">{t('organisation:picklists.list.heading.item-count')}</div>
            <div className="w-1/3 text-center">{t('organisation:picklists.list.heading.type')}</div>
            <div className="w-1/3 text-center">{t('organisation:picklists.list.heading.modified')}</div>
          </div>
          <div className="mr-2 w-8">{/* SPACER */}</div>
          <div className="w-10">{/* SPACER */}</div>
        </div>
        <div>
          {picklists.map((picklist, i) => {
            const isLast = picklists.length === i + 1;
            return (
              <div key={picklist.id} ref={isLast ? lastElementRef : undefined}>
                <DataRow
                  data-cy={`picklist-${picklist.id}`}
                  key={picklist.id}
                  contextMenuItems={[
                    {
                      title: t('organisation:picklists.context-menu.edit'),
                      onClick: () => {
                        setEditingPicklists((prev) => ({ ...prev, [picklist.id]: picklist }));
                      },
                      hide: !!editingPicklists[picklist.id],
                    },
                    {
                      title: t('organisation:picklists.context-menu.make-static'),
                      onClick: () => {
                        changeType(true, picklist);
                      },
                      hide: picklist.isSystem || !!editingPicklists[picklist.id],
                    },
                    {
                      title: t('organisation:picklists.context-menu.make-dynamic'),
                      onClick: () => {
                        changeType(false, picklist);
                      },
                      hide: !picklist.isSystem || !!editingPicklists[picklist.id],
                    },
                    {
                      title: t('organisation:picklists.context-menu.archive'),
                      onClick: () => {
                        setSelectedPicklist(picklist);
                        setShowRemoveModal(true);
                      },
                      hide: picklist.archivedUtc != null || !!editingPicklists[picklist.id],
                    },
                    {
                      title: t('organisation:picklists.context-menu.restore'),
                      onClick: () => {
                        onUnarchive(picklist.id);
                      },
                      hide: picklist.archivedUtc == null || !!editingPicklists[picklist.id],
                    },
                  ]}
                  url={
                    editingPicklists[picklist.id]
                      ? undefined
                      : clientId
                        ? `/clients/${clientId}/picklists/${picklist.id}`
                        : `/builder/${currentTenantId}/picklists/${picklist.id}`
                  }
                >
                  {!editingPicklists[picklist.id] ? (
                    <div className={`flex w-full items-center ${picklist.archivedUtc == null ? 'opapacity-100' : 'opacity-40'}`}>
                      <div className="w-1/2 font-medium text-black">
                        {LanguageUtils.getTranslation('name', picklist.translations) || (
                          <span className="text-gray-3">{LanguageUtils.getTranslation('name', picklist.translations)}</span>
                        )}
                      </div>
                      <div className="flex w-1/2 items-center justify-center">
                        <div className="w-1/3 text-center">{picklist.itemsCount}</div>
                        <div className="w-1/3 text-center">
                          {picklist.isSystem ? t('organisation:picklists.type.static') : t('organisation:picklists.type.dynamic')}
                        </div>
                        <div className="w-1/3 text-center">{DateUtils.formatDate(new Date(picklist.modifiedUtc))}</div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex w-full items-center gap-2 pr-4">
                      <div className="w-1/2">
                        <TranslatableInput
                          translationKey="name"
                          translations={editingPicklists[picklist.id].translations}
                          onTranslationsChange={(x) => updatePicklistTranslations(picklist.id, x)}
                          placeholder={t('organisation:picklists.inputs.name')}
                          maxLength={50}
                        />
                      </div>
                      <div className="w-1/2">
                        <TranslatableInput
                          inputType="multiline"
                          translationKey="description"
                          translations={editingPicklists[picklist.id].translations}
                          rows={1}
                          placeholder={t('organisation:picklists.inputs.description')}
                          onTranslationsChange={(x) => updatePicklistTranslations(picklist.id, x)}
                          maxLength={512}
                        />
                      </div>
                      <div className="flex gap-2">
                        <Button type={ButtonType.PRIMARY} onClick={() => saveEdit(picklist.id)}>
                          {t('organisation:picklists.buttons.save')}
                        </Button>
                        <Button type={ButtonType.SECONDARY} onClick={() => stopEditing(picklist.id)}>
                          {t('organisation:picklists.buttons.cancel')}
                        </Button>
                      </div>
                    </div>
                  )}
                </DataRow>
              </div>
            );
          })}
          {isLoading && (
            <div className="flex flex-col items-center py-6">
              <Loader size={16} centered={false} />
            </div>
          )}
          {!picklists.length && (
            <div data-cy="picklists-empty" className="flex h-full items-center justify-center">
              <div className="my-32 text-center">
                <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
                <div className="text-dpm-20 mt-8">
                  {t('organisation:picklists.empty')}
                  <div className="mt-8">
                    <Button type={ButtonType.PRIMARY} onClick={() => setShowNewPicklistModal(true)}>
                      {t('organisation:picklists.buttons.new-list')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <ModalContext.Provider value={{ open: showRemoveModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveModal(false) }}>
          <ConfirmationModal
            title={t('organisation:picklists.remove-picklist-modal.heading')}
            description={t('organisation:picklists.remove-picklist-modal.text')}
            cancelText={t('organisation:picklists.remove-picklist-modal.buttons.cancel')}
            confirmText={t('organisation:picklists.remove-picklist-modal.buttons.remove')}
            onCancel={() => {
              setShowRemoveModal(false);
              setSelectedPicklist(null);
            }}
            onConfirm={() => {
              setShowRemoveModal(false);
              selectedPicklist && onArchive(selectedPicklist.id);
            }}
            alt
          />
        </ModalContext.Provider>
        <ModalContext.Provider value={{ open: showNewPicklistModal, modalWidth: 'w-2/5', onClose: () => setShowNewPicklistModal(false) }}>
          <StandardModal
            title={t('organisation:picklists.new-picklist-modal.heading')}
            cancelButtonTitle={t('organisation:picklists.new-picklist-modal.buttons.cancel')}
            confirmButtonTitle={t('organisation:picklists.new-picklist-modal.buttons.add')}
            onCancelClick={() => {
              setShowNewPicklistModal(false);
              setNewPickListTranslations({});
            }}
            onConfirmClick={() => {
              setShowNewPicklistModal(false);
              onAdd(newPickListTranslations);
            }}
            confirmDisabled={!LanguageUtils.getTranslation('name', newPickListTranslations).length}
          >
            <TranslatableInput
              translationKey="name"
              translations={newPickListTranslations}
              onTranslationsChange={setNewPickListTranslations}
              data-cy="new-picklist-name"
              label={t('organisation:picklists.inputs.name')}
              placeholder={t('organisation:picklists.inputs.name')}
              maxLength={50}
            />
          </StandardModal>
        </ModalContext.Provider>
      </div>
    </div>
  );
};

export default PicklistEditor;
